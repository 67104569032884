import React, { useContext, useState, useEffect } from "react";
import { MapContext } from "./MapProvider";
import Places from "./Places";
import { useNavigate } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Account from "./Account";
import { account } from "../../images/base64/account";
import { organizations } from "../../images/base64/organizations";
import { map } from "../../images/base64/map";

export default function FooterComponent() {
  let navigate = useNavigate();
  const { details, setDetails } = useContext(MapContext);

  const { showDetails, setShowDetails } = useContext(MapContext);
  const [showPlaces, setShowPlaces] = useState(false);
  const { showAccount, setShowAccount } = useContext(MapContext);

  function toggle() {
    if (details) {
      setShowPlaces(true);
      setShowAccount(false);
      setShowDetails(false);
      setDetails(false);
    } else {
      setDetails(false);
      setShowAccount(false);
      setShowDetails(false);
      setShowPlaces((prev) => !prev);
    }
  }

  function toggleAccount() {
    setShowDetails(false);
    setShowPlaces(false);
    setShowAccount((prev) => !prev);
  }

  function toggleAccount_details() {
    setShowPlaces(false);
    setShowAccount((prev) => !prev);
  }

  return (
    <div>
      <div>
        <footer className="footer">
          <div
            style={{
              position: "absolute",
              top: "50%",
              marginLeft: "4%",
              transform: "translateY(-50%)",
            }}
          >
            <a
              className="Login_back"
              style={{ padding: "0", margin: "0" }}
              onClick={() => navigate("/")}
            >
              <FontAwesomeIcon
                className="Login_back_icon"
                icon={faChevronLeft}
              />
            </a>
          </div>
          <div className="footer_buttons">
            <div className="footer_button">
              <img
                className="footer_pic"
                src={organizations}
                onClick={() => toggle()}
              />
              Organizacje
            </div>
            <div className="footer_button">
              <img
                className="footer_pic"
                src={map}
                onClick={() => {
                  if (showPlaces) {
                    setShowPlaces(false);
                    setShowDetails(false);
                  }
                  if (showAccount) {
                    setShowAccount(false);
                    setShowDetails(false);
                  } else {
                    setShowDetails(false);
                  }
                }}
              />
              Mapa
            </div>
            <div className="footer_button">
              <img
                className="footer_pic"
                src={account}
                onClick={() => toggleAccount()}
              />
              Dobre Ręce
            </div>
          </div>
        </footer>
      </div>
      {showPlaces && (
        <div className="organization">
          <nav>
            <a className="back" onClick={toggle}>
              <FontAwesomeIcon
                className="FontAwesomeIcon"
                icon={faChevronLeft}
              />
              Wróć
            </a>
          </nav>
          <Places />
        </div>
      )}
      {showAccount && (
        <div className="organization">
          {setDetails ? (
            <nav>
              <a className="back" onClick={toggleAccount_details}>
                <FontAwesomeIcon
                  className="FontAwesomeIcon"
                  icon={faChevronLeft}
                />
                Wróć
              </a>
            </nav>
          ) : (
            <nav>
              <a className="back" onClick={toggleAccount}>
                <FontAwesomeIcon
                  className="FontAwesomeIcon"
                  icon={faChevronLeft}
                />
                Wróć
              </a>
            </nav>
          )}

          <Account />
        </div>
      )}
    </div>
  );
}
