import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginBg } from "../../images/base64/loginBg";
import { loginLogo } from "../../images/base64/loginLogo";

import { AuthContext } from "../../Auth/AuthContext";

export default function MainPage() {
  let navigate = useNavigate();
  const { userData, logout } = useContext(AuthContext);

  const [html, setHtml] = useState();

  function handleClickMap() {
    navigate("/Map");
  }

  function handleClickLogin() {
    navigate("/Login");
  }

  function handleClickOrganization() {
    navigate("/Organization");
  }

  useEffect(() => {
    if (!userData.isLoggedIn) {
      setHtml(
        <div className="organization">
          <div>
            <img className="background_pic" src={loginBg} />
          </div>
          <div className="login_content">
            <div className="Login_logo">
              <img src={loginLogo} />
            </div>
            <div className="login_text">Masz coś, co przyda się innym?</div>
            <button onClick={handleClickMap} className="button_login">
              ODDAJ W DOBRE RĘCE!
            </button>
            {/*<div className="login_text">Utwórz panel Organizacji</div>
            <button onClick={handleClickOrganization} className="button_login">
              DODAJ ORGANIZACJĘ
            </button>*/}
            <div className="login_text">Zaloguj się do panelu</div>
            <button onClick={handleClickLogin} className="button_login">
              ZALOGUJ SIĘ
            </button>
          </div>
        </div>
      );
      return;
    }

    switch (userData.role) {
      case "Admin":
        setHtml(
          <div className="organization">
            <div>
              <img className="background_pic" src={loginBg} />
            </div>
            <div className="login_content">
              <div className="Login_logo">
                <img src={loginLogo} />
              </div>
              <h1 className="title_text">Panel administratora</h1>
              <div className="login_text">Zobacz mapę Organizacji</div>
              <button onClick={handleClickMap} className="button_login">
                MAPA ORGANIZACJI
              </button>
              <div className="login_text">Dodaj nową organizację</div>
              <button
                onClick={() => navigate("/Admin/Zapros-organizacje")}
                className="button_login"
              >
                ZAPROŚ ORGANIZACJĘ
              </button>
              <div className="login_text">Wylogowywanie z panelu</div>
              <button onClick={logout} className="button_login">
                WYLOGUJ SIĘ
              </button>
            </div>
          </div>
        );
        return;
      case "Organization":
        setHtml(
          <div className="organization">
            <div>
              <img className="background_pic" src={loginBg} />
            </div>
            <div className="login_content">
              <div className="Login_logo">
                <img src={loginLogo} />
              </div>
              <h1 className="title_text">Panel organizacji</h1>
              <div className="login_text">Zobacz mapę Organizacji</div>
              <button onClick={handleClickMap} className="button_login">
                MAPA ORGANIZACJI
              </button>
              <div className="login_text">Edytuj dane Organizacji</div>
              <button
                onClick={handleClickOrganization}
                className="button_login"
              >
                EDYTUJ DANE
              </button>
              <div className="login_text">Wylogowywanie z panelu</div>
              <button onClick={logout} className="button_login">
                WYLOGUJ SIĘ
              </button>
            </div>
          </div>
        );
        return;
    }
  }, [userData]);

  return html;
}
