import React, { useState } from "react";
import Conversation from "./Conversation";
import chats from "../../images/chats.PNG";
import nav_img from "../../images/nav_img.PNG";

import FUNDACJA_HOSPICYJNA from "../../images/FUNDACJA_HOSPICYJNA.png";
import RC from "../../images/RC.png";
import LEGALIS from "../../images/LEGALIS.png";
import OIRP from "../../images/OIRP.jpg";
import OPERA_BALTYCKA from "../../images/OPERA_BALTYCKA.jpg";
import PRIMASOFT from "../../images/PRIMASOFT.png";

export default function Account() {
  const [showMessages, setShowMessages] = useState(false);

  function handleClick() {
    setShowMessages((prev) => !prev);
  }

  return (
    <div>
      <div className="organization-design" onClick={handleClick}>
        <div>
          <div className="text-1">Aplikacja "Dobre Ręce"</div>
          <div className="text-2">INFORMACJE O APLIKACJI</div>
          <div className="long-text">
            W Internecie już od dawna powinno istniec jedno miejsce, gdzie
            można by szybko dowiedzieć się gdzie w okolicy znajduje się
            organizacja dobroczynna poszukująca rzeczy, której Ty już nie
            potrzebujesz.
            <br />
            <br />
          </div>
          <div className="long-text">
            Projekt "Dobre Ręce" ma na celu utworzenie mapy zapotrzebowania
            materialnego (niefinansowego) organizacji dobroczynnych,
            umożliwiającej potencjalnemu darczyńcy (każdemu użytkownikowi
            Internetu) wsparcia wybranej organizacji dobroczynnej. Na mapie
            znajdziesz tylko podmioty zweryfikowane, w pełni wiarygodne,
            działające legalnie, wyłącznie w celach statutowych (fundacje,
            stowarzyszenia pożytku publicznego, noclegownie, schroniska dla
            zwierzat, etc).
            <br />
            <br />
          </div>
          <div className="long-text">
            Jeśli masz w domu cokolwiek, co nie jest Ci juz potrzebne, a chętnie
            dałbyś temu drugie życie i pomógłbyś tym, którzy moga tego bardzo
            potrzebować - dzieki apce Dobre Rece możesz znaleźć w pobliżu
            organizacje dobroczynną, która takiej rzeczy poszukuje.
            <br />
            <br />
          </div>
          <div className="long-text">
            Mimo ogromnej pomocy materialnej, jaką każdego dnia otrzymują osoby
            potrzebujace, korzystając z ogłoszeń "Oddam za darmo", darczyńca
            nigdy nie ma pewności czy jego pomoc trafi rzeczywiście do tej
            osoby, ktora jej potrzebuje i na nią zasluguje, czy np. do
            handlarza. To organizacje dobroczynne, które zajmują sie daną
            problematyką, mają największą wiedzę czego potrzebują ich
            podopieczni, dlatego to za ich pośrednictwem Twoja pomoc powinna
            trafiać do docelowych beneficjentow; np. osób w trudnej sytuacji
            materialnej.
            <br />
            <br />
          </div>
          <div className="long-text">
            Wierzymy, że mając dokladną informacje o zapotrzebowaniu organizacji
            dobroczynnych, ludzie o dobrym sercu, znajdą w sobie energię aby w
            wolnej chwili oddać ją w Dobre Rece wybranej przez siebie
            organizacji dobroczynnej.
          </div>
          <div className="logo-img">
            <img src={FUNDACJA_HOSPICYJNA} />
            <img src={RC} />
            <img src={LEGALIS} />
            <img src={OIRP} />
            <img src={OPERA_BALTYCKA} />
            <img src={PRIMASOFT} />
          </div>
        </div>
        {/*<img src={chats}></img>*/}
      </div>
      {/*showMessages && (
        <div className="organization">
          <div className="conversation_nav" onClick={handleClick}>
            <img
              className="nav_img" src={nav_img}
            />
          </div>
          <Conversation />
        </div>
      )*/}
    </div>
  );
}
