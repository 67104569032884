import React, { useContext } from "react";
import { MapContext } from "./MapProvider";
import wa from "../../images/wa.png";

export default function Details2({ data: organization }) {
  const { showAccount, setShowAccount } = useContext(MapContext);

  return (
    <div className="organization-design">
      <div className="part1">
        <div className="text_1">
          <b>GDZIE ZOSTAWIĆ RZECZY?</b>
        </div>
        {/*<div className="picture1">
          {organization.photo && (
            <img
              className="picture1_img"
              src={`data:image/png;base64,${organization.photo}`}
            />
          )}
        </div>*/}
        <div className="details_part1">
          <div>
            Adres: ul. {organization.address.streetName}{" "}
            {organization.address.streetNumber}
          </div>
          {/* <div>Miejsce: recepcja Hospicjum</div> */}
        </div>
      </div>
      <div className="part2">
        <div className="details_part1">
          <p>Zanim oddasz w Dobre Ręce skontaktuj się z nami:</p>
          <img src={wa} style={{ width: "20px", verticalAlign: "bottom" }} />
          <a
            className="underline-button"
            href={`tel:+48${organization.manager.phoneNumber}`}
          >
            +48 {organization.manager.phoneNumber}
          </a>
        </div>
      </div>
    </div>
  );
}
