import React from "react";

const Details1 = ({ data: organization }) => {
  console.log(organization);
  return (
    <div className="organization-design">
      <div>
        <div className="text-1">{organization.name}</div>
        <div className="text-2">INFORMACJE O ORGANIZACJI</div>
        <div
          className="long-text"
          dangerouslySetInnerHTML={{
            __html: organization.fullDescription.replace(/\n/g, "<br>"),
          }}
        ></div>
      </div>
      <div style={{ bottom: "0px" }}>
        <div className="info-container">
          <div className="picture">
            {organization.manager.photo && (
              <img
                src={`data:image/png;base64,${organization.manager.photo}`}
                style={{ height: "8vh", width: "8vh", borderRadius: "50%" }}
              />
            )}
          </div>
          <div className="text-container1">
            <div className="vertical-line"></div>

            <div>
              <b className="title1">WOLONTARIUSZ ORGANIZACJI</b>
            </div>
            <div>
              {organization.manager.name} {organization.manager.surname}
            </div>
            <a
              className="underline-button"
              href={`tel:+48${organization.manager.phoneNumber}`}
            >
              +48 {organization.manager.phoneNumber}
            </a>
          </div>
        </div>
        <div className="bottom-text">
          Wspólnie tworzymy coś wyjątkowego, co ma realny wpływ na życie innych.
          Razem możemy zdziałać wiele. Dziękuję za możliwość bycia częścią tego
          niezwykłego hospicjum.
        </div>
      </div>
    </div>
  );
};

export default Details1;
