import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MapPage from "./pages/Map/MapPage";
import { MapProvider } from "./pages/Map/MapProvider";
import "./App.css";
import MainPage from "./pages/Main/Page";
import LoginScreen from "./pages/Login/LoginScreen";
import OrganizationScreen from "./pages/Organization/OrganizationScreen";
import InviteOrganization from "./pages/Admin/InviteOrganization";
import AcceptInvitation from "./pages/Organization/AcceptInvitation";
import { AuthProvider } from "./Auth/AuthContext";

const App = () => {
  return (
    <AuthProvider>
      <MapProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainPage />}></Route>
            <Route path="/Map" element={<MapPage />}></Route>
            <Route path="/Login" element={<LoginScreen />}></Route>
            <Route
              path="/Organization"
              element={<OrganizationScreen />}
            ></Route>
            <Route
              path="/Admin/Zapros-organizacje"
              element={<InviteOrganization />}
            ></Route>
            <Route
              path="/Witamy-w-Dobrych-Rekach"
              element={<AcceptInvitation />}
            ></Route>
          </Routes>
        </BrowserRouter>
      </MapProvider>
    </AuthProvider>
  );
};

export default App;
