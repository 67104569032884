import React, { useState, useEffect, useContext } from "react";
import ApolloClient from "../../configuration/Apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { loginBg } from "../../images/base64/loginBg";
import { loginLogo } from "../../images/base64/loginLogo";

import { AuthContext } from "../../Auth/AuthContext";

import { LOGIN } from "./query";

export default function LoginScreen() {
  let navigate = useNavigate();
  const { userData, login } = useContext(AuthContext);

  useEffect(() => {
    if (userData?.isLoggedIn) {
      navigate("/");
    }
  });

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [notification, setNotification] = useState({
    message: "",
    type: "unset",
    style: {
      textAlign: "center",
      fontWeight: "bold",
      marginTop: "10px",
      marginBottom: "20px",
      fontSize: "18px",
    },
  });

  function handleClickBack() {
    navigate("/");
  }

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setNotification({
      message: "",
      type: "unset",
      style: notification.style,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.email.trim() || !formData.password.trim()) {
      setNotification({
        message: "Podaj dane do logowania.",
        type: "warning",
        style: {
          ...notification.style,
          color: "#ff5f15",
        },
      });
      return;
    }

    try {
      const { data } = await ApolloClient.mutate({
        mutation: LOGIN,
        variables: {
          email: formData.email,
          password: formData.password,
        },
      });
      if (data?.login) {
        login(data?.login.role, data?.login.recordId);
        navigate("/");
      } else {
        console.error("Failed to sent invitation: no response");
        setNotification({
          message: "Ups coś poszło nie tak, skontaktuj się z nami!",
          type: "error",
          style: {
            ...notification.style,
            color: "#cc3300",
          },
        });
      }
    } catch (error) {
      console.error("Failed to login:", error);
      setNotification({
        message: "Logowanie nieudane, nieprawidłowe dane.",
        type: "error",
        style: {
          ...notification.style,
          color: "#cc3300",
        },
      });
    }
  };

  return (
    <div className="organization">
      <div>
        <img className="background_pic" src={loginBg} />
      </div>
      <div className="Login_icon">
        <a className="Login_back" onClick={handleClickBack}>
          <FontAwesomeIcon className="Login_back_icon" icon={faChevronLeft} />
        </a>
      </div>
      <div className="login_content">
        <div className="Login_logo">
          <img src={loginLogo} />
        </div>
      </div>
      <div className="Login_container">
        <form className="Login_form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="email">E-MAIL</label>
            <input
              className="Login_input"
              name="email"
              placeholder="wpisz adres e-mail"
              value={formData.email}
              onChange={onChangeHandler}
            />
          </div>
          <div className="form-group">
            <label className="password">HASŁO</label>
            <input
              className="Login_input"
              name="password"
              type="password"
              placeholder="wpisz hasło"
              onChange={onChangeHandler}
            />
          </div>
          {/* <a className="forgot-password">Nie pamiętasz swojego hasła?</a> */}
          <div>
            {notification.message && (
              <div style={notification.style}>{notification.message}</div>
            )}
          </div>
          <div className="Login_buttons">
            <button className="button_login_screen1" type="submit">
              ZALOGUJ SIĘ
            </button>
            {/* <div className="login_text1">Nie masz konta?</div>

          <button className="button_login_screen2">
            ZAREJESTRUJ ORGANIZACJĘ
          </button> */}
          </div>
        </form>
      </div>
    </div>
  );
}
