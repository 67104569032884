import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loginBg } from "../../images/base64/loginBg";

import ApolloClient from "../../configuration/Apollo";
import { ACCEPT_INVITATION } from "./query";

export default function AcceptInvitation() {
  let navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailParam = searchParams.get("email");
  const tokenParam = searchParams.get("token");

  useEffect(() => {
    if (!emailParam || !tokenParam) {
      navigate("/");
    }
  }, [emailParam, tokenParam, navigate]);

  const [formData, setFormData] = useState({
    email: emailParam || "",
    token: tokenParam || "",
    password: "",
  });

  const [notification, setNotification] = useState({
    message: "",
    type: "unset",
    style: {
      textAlign: "center",
      fontWeight: "bold",
      marginTop: "10px",
      marginBottom: "20px",
      fontSize: "18px",
    },
  });

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setNotification({
      message: "",
      type: "unset",
      style: notification.style,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.password.trim()) {
      setNotification({
        message: "Podaj hasło.",
        type: "warning",
        style: {
          ...notification.style,
          color: "#ff5f15",
        },
      });
      return;
    }

    try {
      const { data } = await ApolloClient.mutate({
        mutation: ACCEPT_INVITATION,
        variables: {
          email: formData.email,
          token: formData.token,
          password: formData.password,
        },
      });
      if (data?.organization?.acceptInvitation) {
        setNotification({
          message: "Zaproszenie przyjęte!",
          type: "success",
          style: {
            ...notification.style,
            color: "#004aad",
          },
        });
        setFormData({ password: "" });
      } else {
        console.error("Failed to sent invitation: no response");
        setNotification({
          message: "Ups coś poszło nie tak, skontaktuj się z nami!",
          type: "error",
          style: {
            ...notification.style,
            color: "#cc3300",
          },
        });
      }
    } catch (error) {
      console.error("Failed to sent invitation:", error);
      setNotification({
        message: "Ups coś poszło nie tak, skontaktuj się z nami!",
        type: "error",
        style: {
          ...notification.style,
          color: "#cc3300",
        },
      });
    }
  };

  const handleNavigate = async (event) => {
    event.preventDefault();
    navigate("/login");
  };

  const headerStyle = {
    color: "#004aad",
    textAlign: "center",
    whiteSpace: "normal",
    fontWeight: "bold",
    letterSpacing: "1px",
    marginTop: "0px",
  };

  return (
    <div className="organization">
      <div>
        <img className="background_pic" src={loginBg} />
      </div>
      <div className="Login_container" style={{ top: "25%" }}>
        <h2 style={headerStyle}> Witamy w Dobrych Rękach!</h2>
        <form className="Login_form" onSubmit={handleSubmit}>
          {notification.type !== "success" && (
            <div className="form-group">
              <label className="email">HASŁO</label>

              <input
                className="Login_input"
                name="password"
                type="password"
                placeholder="Podaj hasło do swojego konta."
                value={formData.password}
                onChange={onChangeHandler}
              />
            </div>
          )}
          <div>
            {notification.message && (
              <div style={notification.style}>{notification.message}</div>
            )}
          </div>
          <div className="Login_buttons">
            {notification.type !== "success" && (
              <button className="button_login_screen1" type="submit">
                DOŁĄCZ DO NAS!
              </button>
            )}
            {notification.type === "success" && (
              <button className="button_login_screen1" onClick={handleNavigate}>
                ZALOGUJ SIĘ!
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
