import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { loginBg } from "../../images/base64/loginBg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../Auth/AuthContext";

import ApolloClient from "../../configuration/Apollo";
import { INVITE_ORGANIZATION } from "./query";

export default function InviteOrganization() {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
  });

  const { userData } = useContext(AuthContext);

  useEffect(() => {
    if (
      !userData ||
      userData.isLoggedIn !== true ||
      userData.role !== "Admin"
    ) {
      navigate("/");
    }
  }, [userData, navigate, AuthContext]);

  const [notification, setNotification] = useState({
    message: "",
    type: "unset",
    style: {
      textAlign: "center",
      fontWeight: "bold",
      marginTop: "10px",
      marginBottom: "20px",
      fontSize: "18px",
    },
  });

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setNotification({
      message: "",
      type: "unset",
      style: notification.style,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.email.trim()) {
      setNotification({
        message: "Podaj adres e-mail.",
        type: "warning",
        style: {
          ...notification.style,
          color: "#ff5f15",
        },
      });
      return;
    }

    try {
      const { data } = await ApolloClient.mutate({
        mutation: INVITE_ORGANIZATION,
        variables: {
          email: formData.email,
        },
      });
      if (data?.admin?.inviteOrganization) {
        setNotification({
          message: "Zaproszenie wysłane!",
          type: "success",
          style: {
            ...notification.style,
            color: "#004aad",
          },
        });
        setFormData({ email: "" });
      } else {
        console.error("Failed to sent invitation: no response");
        setNotification({
          message: "Nie udało się wysłać zaproszenia, sprobuj później.",
          type: "error",
          style: {
            ...notification.style,
            color: "#cc3300",
          },
        });
      }
    } catch (error) {
      console.error("Failed to sent invitation:", error);
      setNotification({
        message: "Nie udało się wysłać zaproszenia, sprobuj później.",
        type: "error",
        style: {
          ...notification.style,
          color: "#cc3300",
        },
      });
    }
  };

  const headerStyle = {
    color: "#004aad",
    textAlign: "center",
    whiteSpace: "normal",
    fontWeight: "bold",
    letterSpacing: "1px",
    marginTop: "0px",
  };

  return (
    <div className="organization">
      <div>
        <img className="background_pic" src={loginBg} />
      </div>
      <div className="Login_icon">
        <a className="Login_back" onClick={() => navigate("/")}>
          <FontAwesomeIcon className="Login_back_icon" icon={faChevronLeft} />
        </a>
      </div>
      <div className="Login_container" style={{ top: "25%" }}>
        <h2 style={headerStyle}> Zaproś Organizację</h2>
        <form className="Login_form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="email">E-MAIL</label>
            <input
              className="Login_input"
              name="email"
              placeholder="wpisz adres e-mail"
              value={formData.email}
              onChange={onChangeHandler}
            />
          </div>
          <div>
            {notification.message && (
              <div style={notification.style}>{notification.message}</div>
            )}
          </div>
          <div className="Login_buttons">
            <button className="button_login_screen1" type="submit">
              WYŚLIJ ZAPROSZENIE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
