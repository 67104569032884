import { gql } from "@apollo/client";

export const ADMIN_LOGOUT = gql`
  mutation logout {
    admin {
      logout
    }
  }
`;

export const ORGANIZATION_LOGOUT = gql`
  mutation logout {
    organization {
      logout
    }
  }
`;

export const WHO_AM_I_ADMIN = gql`
  query WhoAmI {
    whoAmI {
      admin {
        id
        email
      }
    }
  }
`;

export const WHO_AM_I_ORGANIZATION = gql`
  query whoAmI {
    whoAmI {
      organization {
        id
        email
        organization {
          distanceFromUser
          email
          fieldOfOperation
          fullDescription
          id
          krsCategory
          krsNumber
          latitude
          logo
          longitude
          name
          needs
          photo
          shortDescription
          address {
            city
            streetName
            streetNumber
          }
          manager {
            id
            name
            phoneNumber
            photo
            surname
          }
        }
      }
    }
  }
`;
