import React, { useState, useEffect, createContext } from 'react';

export const MapContext = createContext();

export const MapProvider = ({ children }) => {
  const [ showDetails, setShowDetails ] = useState(false);
  const [ details, setDetails ] = useState(false);
  const [ searchText, setSearchText ] = useState("");
  const [ showAccount, setShowAccount ] = useState(false);
  const [ userLongitude, setUserLongitude ] = useState(18.6543407);
  const [ userLatitude, setUserLatitude ] = useState(54.3567841);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
            setUserLatitude(position.coords.latitude);
            setUserLongitude(position.coords.longitude);
        },
        (error) => {
            console.log(error.message);
        },
        {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        }
      );
    } else {
        console.log("Geolocation is not available");
    }
  }, []);

  return (
    <MapContext.Provider value={{ showDetails, setShowDetails, details, setDetails, searchText, setSearchText, showAccount, setShowAccount, userLongitude, setUserLongitude, userLatitude, setUserLatitude }}>
      {children}
    </MapContext.Provider>
  );
};
