import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ORGANIZATION } from "../../configuration/queries";
import ApolloClient from "../../configuration/Apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Details1 from "./Details1";
import Details2 from "./Details2";
import FooterComponent from "./Footer";
import wa from "../../images/wa.png";

const Organization = ({ data }) => {
  const [ShowInfo, setShowInfo] = React.useState(false);
  const [ShowPlace, setShowPlace] = React.useState(false);

  // this request is unnecessary but cba.
  const {
    loading,
    error,
    data: organizationData,
  } = useQuery(GET_ORGANIZATION, {
    client: ApolloClient,
    variables: {
      id: data.id,
    },
    fetchPolicy: "network-only",
  });

  if (loading) return <p className="loading">Loading...</p>;
  if (error) return <p className="loading">Error: {error.message}</p>;

  const organization = organizationData.organizations[0];

  const needsList = organization.needs.map((need, index) => (
    <li key={index}>{need}</li>
  ));

  function Toggle() {
    setShowInfo((prev) => !prev);
  }

  function Toggle1() {
    setShowPlace((prev) => !prev);
  }

  const createDirectionsLink = () => {
    const destination = `${organization.address.streetName} ${organization.address.streetNumber}`;
    return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      destination
    )}&travelmode=driving`;
  };

  return (
    <div>
      <div className="organization-design">
        <div>
          {organization.logo && (
            <img
              className="pic1"
              src={`data:image/png;base64,${organization.logo}`}
              alt="Organization Logo"
            />
          )}
          <div className="text-organization">
            <div>
              <b>{organization.name}</b>
            </div>
            <div>
              <b>adres: </b>
              {organization.address.streetName}{" "}
              {organization.address.streetNumber}
            </div>
            <div>
              <b>KRS: </b>
              {organization.krsNumber}
            </div>
          </div>
          <div className="info-container">
            <div className="pic-container">
              {organization.manager.photo && (
                <img
                  className="pic2"
                  src={`data:image/png;base64,${organization.manager.photo}`}
                  alt="Manager Photo"
                />
              )}
            </div>
            <div className="text-container">
              <b>Opiekun organizacji</b>
              <div>
                {organization.manager.name} {organization.manager.surname}
              </div>
              <img
                src={wa}
                style={{ width: "20px", verticalAlign: "bottom" }}
              />
              <a
                className="underline-button"
                href={`tel:+48${organization.manager.phoneNumber}`}
              >
                +48 {organization.manager.phoneNumber}
              </a>
            </div>
          </div>
        </div>
        <div>
          <p className="list-title">Zapotrzebowanie organizacji:</p>
          <ul className="list">
            <div>{needsList}</div>
          </ul>
        </div>
        <div className="bottom">
          <div className="text1">
            <p>
              Prosimy o przekazywanie rzeczy nowych bądź używanych, ale
              nienoszących śladów używania.
            </p>
          </div>

          <p className="text2">Podrzuć sam i pozostaw w Dobrych Rękach</p>
          <div className="buttons">
            <button className="button1" onClick={() => Toggle1()}>
              Gdzie zostawic rzeczy?
            </button>
            <button
              className="button2"
              onClick={() => window.open(createDirectionsLink(), "_blank")}
            >
              Wskazówki dojazdu
            </button>
            <br></br>
            <button className="underline-button" onClick={() => Toggle()}>
              Czytaj więcej o tej Organizacji
            </button>
          </div>
        </div>
      </div>
      <FooterComponent />

      {ShowInfo && (
        <div className="organization">
          <nav>
            <a className="back" onClick={Toggle}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="FontAwesomeIcon"
              />
              Wróć
            </a>
          </nav>
          <Details1 data={organization} />
          <FooterComponent />
        </div>
      )}
      {ShowPlace && (
        <div className="organization">
          <nav>
            <a className="back" onClick={Toggle1}>
              <FontAwesomeIcon
                className="FontAwesomeIcon"
                icon={faChevronLeft}
              />
              Wróć
            </a>
          </nav>
          <Details2 data={organization} />
          <FooterComponent />
        </div>
      )}
    </div>
  );
};

export default Organization;
