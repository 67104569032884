import { ApolloClient, InMemoryCache } from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

const client = new ApolloClient({
  // uri: "https://drc-primasoft.koyeb.app/graphql",
  // uri: "http://localhost:51600/graphql",
  cache: new InMemoryCache(),
  // credentials: "include",
  link: createUploadLink({
    uri: "https://drc-primasoft.koyeb.app/graphql",
    // uri: "http://localhost:51600/graphql",
    credentials: "include",
    headers: {
      "GraphQL-preflight": "1",
    },
  }),
});

export default client;
