import React, { createContext, useState, useEffect } from "react";
import {
  WHO_AM_I_ADMIN,
  WHO_AM_I_ORGANIZATION,
  ADMIN_LOGOUT,
  ORGANIZATION_LOGOUT,
} from "./query";
import ApolloClient from "../configuration/Apollo";
import { ApolloError } from "@apollo/react-hooks";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState({
    isLoggedIn: false,
    role: "",
    id: "",
    data: null,
  });

  const unsetUserData = () => {
    setUserData({ isLoggedIn: false, role: "", id: "", data: null });
    localStorage.removeItem("userData");
  };

  // Check local storage on initial component mount
  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    if (storedUserData && storedUserData.isLoggedIn) {
      switch (storedUserData.role) {
        case "Admin":
          setAdmin(storedUserData);
          break;
        case "Organization":
          setOrganization(storedUserData);
          break;
        default:
        // throw new Error("Login failed."); // TODO: What to do?
      }
      return;
    }
  }, []);

  const setAdmin = async (admin) => {
    try {
      const { data } = await ApolloClient.query({
        query: WHO_AM_I_ADMIN,
        fetchPolicy: "network-only",
      });
      console.log(data);
      const user = {
        ...admin,
        data: { email: data.whoAmI.admin.email },
      };
      setUserData(user);
      localStorage.setItem("userData", JSON.stringify(user));
    } catch (error) {
      if (error instanceof ApolloError) {
        unsetUserData();
      } else {
        throw error;
      }
    }
  };

  const setOrganization = async (organization) => {
    try {
      const { data } = await ApolloClient.query({
        query: WHO_AM_I_ORGANIZATION,
        fetchPolicy: "network-only",
      });
      const user = {
        ...organization,
        data: data.whoAmI.organization,
      };
      setUserData(user);
      localStorage.setItem("userData", JSON.stringify(user));
    } catch (error) {
      if (error instanceof ApolloError) {
        unsetUserData();
      } else {
        throw error;
      }
    }
  };

  const login = (role, id) => {
    // call to login mutation should be here and not in login page.
    const user = {
      isLoggedIn: true,
      role: role,
      id: id,
    };
    switch (role) {
      case "Admin":
        setAdmin(user);
        break;
      case "Organization":
        setOrganization(user);
        break;
      default:
        throw new Error("Login failed."); // TODO: What to do?
    }
  };

  const logout = async () => {
    var role = userData?.role;
    if (!role) {
      const storedUserData = JSON.parse(localStorage.getItem("userData"));
      role = storedUserData?.role;
    }
    var isLoggedOut = false;
    try {
      switch (role) {
        case "Admin":
          var response = await ApolloClient.mutate({
            mutation: ADMIN_LOGOUT,
          });
          isLoggedOut = response?.data?.admin?.logout;
          break;
        case "Organization":
          var response = await ApolloClient.mutate({
            mutation: ORGANIZATION_LOGOUT,
          });
          isLoggedOut = response?.data?.organization?.logout;
          break;
      }
      if (isLoggedOut) {
        unsetUserData();
      } // What if it fails?
    } catch (error) {
      if (error instanceof ApolloError) {
        unsetUserData();
      } else {
        throw error;
      }
    }
  };

  return (
    <AuthContext.Provider value={{ userData, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
