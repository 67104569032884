import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ApolloClient from "../../configuration/Apollo";
import { loginBg } from "../../images/base64/loginBg";
import { organizationLogo } from "../../images/base64/organizationLogo";
import Select from "react-select";
import { AuthContext } from "../../Auth/AuthContext";
import { ADD_ORGANIZATION, EDIT_ORGANIZATION } from "./query";
import Dropzone from "react-dropzone";

const SelectOrganizationDestiny = [
  { value: "HEALTH_CARE", label: "Opieka zdrowotna" },
  { value: "REFUGEES_AID", label: "Pomoc dla uchodźców" },
  { value: "ANIMAL_CARE", label: "Opieka nad zwierzętami" },
  { value: "HOMELESS_AID", label: "Pomoc dla bezdomnych" },
  { value: "PUBLIC_FUNDRAISING", label: "Zbiórki publiczne" },
];
const SelectKRSCategory = [
  {
    value: "BUSINESSES_REGISTRY",
    label: "Rejestr działalności gospodarczej",
  },
  {
    value: "NON_PROFIT_ORGANIZATIONS_REGISTRY",
    label: "Rejestr organizacji non-profit",
  },
  {
    value: "INSOLVENT_DEBTORS_REGISTRY",
    label: "Rejestr dłużników niewypłacalnych",
  },
];

export default function OrganizationScreen() {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  const [mutation, setMutation] = useState(ADD_ORGANIZATION);
  const [mutationInputName, setMutationInputName] = useState("addInput");

  const [isLogoData, setIsLogoData] = useState(false);
  const [isManagerPhotoData, setIsManagerPhotoData] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    logo: null,
    krsCategory: "",
    KRSNumber: "",
    city: "",
    street: "",
    latitude: 0.0,
    longitude: 0.0,
    number: "",
    managerFirstName: "",
    managerLastName: "",
    managerPhone: "",
    managerPhoto: null,
    fieldOfOperation: "",
    needs: [],
    newNeed: "",
    shortDesc: "",
    fullDesc: "",
  });

  useEffect(() => {
    if (!userData) {
      navigate("/");
    } else if (
      userData.isLoggedIn === false ||
      userData.role !== "Organization"
    ) {
      navigate("/");
    } else {
      const org = userData.data.organization;
      if (org) {
        setFormData({
          name: org.name,
          logo: null,
          krsCategory: org.krsCategory,
          KRSNumber: `${org.krsNumber}`,
          city: org.address.city,
          street: org.address.streetName,
          latitude: parseFloat(org.latitude),
          longitude: parseFloat(org.longitude),
          number: org.address.streetNumber,
          managerFirstName: org.manager.name,
          managerLastName: org.manager.surname,
          managerPhone: org.manager.phoneNumber,
          managerPhoto: null,
          fieldOfOperation: org.fieldOfOperation,
          needs: org.needs,
          newNeed: "",
          shortDesc: "",
          fullDesc: org.fullDescription,
        });

        if(org.logo) {
          setIsLogoData(true);
        }
        if(org.manager.photo) {
          setIsManagerPhotoData(true);
        }

        setMutation(EDIT_ORGANIZATION);
        setMutationInputName("editInput");
      } else {
        setMutation(ADD_ORGANIZATION);
        setMutationInputName("addInput");
      }
    }
  }, [userData, navigate]);

  function handleClickBack() {
    navigate("/");
  }

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleKRSCategoryChange = (selectedOption) => {
    setFormData({ ...formData, krsCategory: selectedOption.value });
  };

  const handleFieldOfOperation = (selectedOption) => {
    setFormData({ ...formData, fieldOfOperation: selectedOption.value });
  };

  const addNeed = () => {
    if (formData.newNeed.trim() !== "") {
      setFormData({
        ...formData,
        needs: [...formData.needs, formData.newNeed.trim()],
        newNeed: "",
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addNeed();
    }
  };

  const removeNeed = (index) => {
    const updatedNeeds = formData.needs.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      needs: updatedNeeds,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let address = {
      city: formData.city,
      street: formData.street,
      number: formData.number,
    }
    let formAddress = await getCoordinatesFromNominatim(address);

    try {
      const input = {
        name: formData.name,
        krsCategory: formData.krsCategory,
        krsNumber: formData.KRSNumber,
        fieldOfOperation: formData.fieldOfOperation,
        shortDescription: formData.shortDesc,
        fullDescription: formData.fullDesc,
        logo: formData.logo,
        needs: formData.needs,
        address: {
          city: formData.city,
          street: formData.street,
          number: formData.number,
          latitude: formAddress.lat,
          longitude: formAddress.lon,
        },
        manager: {
          firstName: formData.managerFirstName,
          lastName: formData.managerLastName,
          phone: formData.managerPhone,
          photo: formData.managerPhoto,
        },
      };

      let filteredInput = null;
      if (mutationInputName === "editInput") {
        const filteredInput = Object.fromEntries(
          Object.entries(input)
            .map(([key, value]) => {
              if (typeof value === "object" && value !== null) {
                // If value is an object, apply cleaning operation to its entries
                const cleanedValue = Object.fromEntries(
                  Object.entries(value).filter(
                    ([innerKey, innerValue]) =>
                      innerValue !== null &&
                      innerValue !== "" &&
                      (typeof innerValue !== "string" ||
                        innerValue.trim() !== "")
                  )
                );
                return [key, cleanedValue];
              } else {
                // For non-object values, apply cleaning operation
                return [
                  key,
                  value !== null &&
                  value !== "" &&
                  (typeof value !== "string" || value.trim() !== "")
                    ? value
                    : undefined,
                ];
              }
            })
            .filter(([key, value]) => value !== undefined) // Filter out undefined values
        );

        if (filteredInput?.needs) {
          filteredInput.needs = Object.values(filteredInput.needs);
        }
      }

      const { data } = await ApolloClient.mutate({
        mutation: mutation,
        variables: {
          [mutationInputName]: filteredInput ?? input,
        },
      });

      setMutation(EDIT_ORGANIZATION);
      setMutationInputName("editInput");

      navigate("/");

    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNameChange = (event) => {
    const { name, value } = event.target;
    if (/^[a-zA-Z ]+$/.test(value) || value === "") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handlePhoneChange = (event) => {
    const { name, value } = event.target;
    if (/^\d{0,9}$/.test(value) || value === "") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const buttonStyle = {
    display: "inline-block",
    cursor: "pointer",
    fontSize: "1.7vh",
    lineHeight: 1,
    borderRadius: "25px",
    transitionDuration: "0.3s",
    border: "1px solid transparent",
    letterSpacing: "1px",
    minWidth: "30px",
    whiteSpace: "normal",
    textAlign: "center",
    color: "#fff",
    backgroundColor: "#0751b1",
    height: "3.1vh",
    marginTop: "1.5vh",
    // marginBottom: "1.5vh",
    // width: "30px",
    justifyContent: "center",
  };

  const [logo, setLogo] = useState(null);
  const [isLogoSet, setIsLogoSet] = useState(false);

  const [managerPhoto, setManagerPhoto] = useState(null);
  const [isManagerPhotoSet, setIsManagerPhotoSet] = useState(null);

  const handleLogoDrop = (droppedFiles) => {
    setLogo(droppedFiles[0]);
    setIsLogoSet(true);
    setFormData((prevData) => ({
      ...prevData,
      logo: droppedFiles[0],
    }));
  };

  const removeLogo = () => {
    setIsLogoData(false);
    setLogo(null);
    setIsLogoSet(false);
    setFormData((prevData) => ({
      ...prevData,
      logo: null,
    }));
  };

  const handleManagerPhotoDrop = (droppedFiles) => {
    setManagerPhoto(droppedFiles[0]);
    setIsManagerPhotoSet(true);
    setFormData((prevData) => ({
      ...prevData,
      managerPhoto: droppedFiles[0],
    }));
  };

  const removeManagerPhotoo = () => {
    setIsManagerPhotoData(false);
    setManagerPhoto(null);
    setIsManagerPhotoSet(false);
    setFormData((prevData) => ({
      ...prevData,
      logo: null,
    }));
  };
  const managerPhotoHtml = (
    <div className="organization_container" style={{ paddingTop: "3vh" }}>
      <div className="organization_add">
        <div className="organization_text">
          {(isManagerPhotoSet || isManagerPhotoData) ? (
            <div>
              <div>Dodano zdjęcie kierownika organizacji!</div>
              <button style={buttonStyle}>
                Usuń zdjęcie
                <button
                  onClick={removeManagerPhotoo}
                  style={{
                    fontWeight: "600",
                    color: "#fff",
                    background: "none",
                    border: "none",
                    display: "inline",
                    marginLeft: "5px",
                    marginRight: "3px",
                  }}
                >
                  X
                </button>
              </button>
            </div>
          )
          : (
            <Dropzone onDrop={handleManagerPhotoDrop} accept="image/*">
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div>Dodaj zdjęcie kierownika organizacji</div>
                  <button style={buttonStyle}>+</button>
                </div>
              )}
            </Dropzone>
          )}
        </div>
      </div>
    </div>
  );

  const logoHtml = (
    <div className="organization_container" style={{ paddingTop: "3vh" }}>
      <div className="organization_add">
        <div className="organization_text">
          {(isLogoSet || isLogoData) ? (
            <div>
              <div>Dodano zdjęcie loga organizacji!</div>
              <button style={buttonStyle}>
                Usuń zdjęcie
                <button
                  onClick={removeLogo}
                  style={{
                    fontWeight: "600",
                    color: "#fff",
                    background: "none",
                    border: "none",
                    display: "inline",
                    marginLeft: "5px",
                    marginRight: "3px",
                  }}
                >
                  X
                </button>
              </button>
            </div>
          )
          : (
            <Dropzone onDrop={handleLogoDrop} accept="image/*">
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div>Dodaj zdjęcie loga organizacji</div>
                  <button style={buttonStyle}>+</button>
                </div>
              )}
            </Dropzone>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div>
        <img className="background_pic" src={loginBg} alt="Background" />
      </div>
      <div className="Login_icon">
        <a className="Login_back" href="/" onClick={handleClickBack}>
          <FontAwesomeIcon className="Login_back_icon" icon={faChevronLeft} />
        </a>
      </div>
      <div className="organization_outer">
        <div className="organization_screen_text">
          Uzupełnij panel Organizacji
        </div>
        {logoHtml}
        {managerPhotoHtml}
        <form className="organization_container" onSubmit={handleSubmit}>
          <div className="containers_organization">
            <div style={{ position: "relative" }}>
              <div
                style={{
                  border: "0.1px solid #00000036",
                  width: "200px",
                  position: "absolute",
                  top: "60%",
                  left: "9%",
                }}
              ></div>
              <input
                className="organization_input"
                name="name"
                placeholder="Nazwa organizacji"
                value={formData.name}
                onChange={onChangeHandler}
              />
            </div>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  border: " 0.1px solid #00000036",
                  width: "200px",
                  position: "absolute",
                  top: "60%",
                  left: "9%",
                }}
              ></div>
              <input
                className="organization_input"
                name="fullDesc"
                placeholder="Opis organizacji"
                value={formData.fullDesc}
                onChange={onChangeHandler}
              />
            </div>
            <div style={{ position: "relative" }}>
              <div className="organization_input">
                <Select
                  className="organization_input_select"
                  name="KRSCategory"
                  placeholder="Wybierz KRS działalności"
                  value={SelectKRSCategory.find(
                    (e) => e.value === formData.krsCategory
                  )}
                  onChange={handleKRSCategoryChange}
                  options={SelectKRSCategory}
                />
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  border: "0.1px solid #00000036",
                  width: "200px",
                  position: "absolute",
                  top: "60%",
                  left: "9%",
                }}
              ></div>
              <input
                className="organization_input"
                name="KRSNumber"
                placeholder="Numer KRS"
                value={formData.KRSNumber}
                onChange={onChangeHandler}
              />
            </div>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  border: "0.1px solid #00000036",
                  width: "200px",
                  position: "absolute",
                  top: "60%",
                  left: "9%",
                }}
              ></div>
              <input
                className="organization_input"
                name="city"
                placeholder="Miasto"
                value={formData.city}
                onChange={handleNameChange}
              />
            </div>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  border: "0.1px solid #00000036",
                  width: "200px",
                  position: "absolute",
                  top: "60%",
                  left: "9%",
                }}
              ></div>
              <input
                className="organization_input"
                name="street"
                placeholder="Ulica"
                value={formData.street}
                onChange={onChangeHandler}
              />
            </div>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  border: "0.1px solid #00000036",
                  width: "200px",
                  position: "absolute",
                  top: "60%",
                  left: "9%",
                }}
              ></div>
              <input
                className="organization_input"
                name="number"
                placeholder="Numer"
                value={formData.number}
                onChange={onChangeHandler}
              />
            </div>
            <div style={{ position: "relative" }}>
              <div className="organization_input">
                <Select
                  className="organization_input_select"
                  name="fieldOfOperation"
                  placeholder="Wybierz obszar działalności"
                  value={SelectOrganizationDestiny.find(
                    (e) => e.value === formData.fieldOfOperation
                  )}
                  onChange={handleFieldOfOperation}
                  options={SelectOrganizationDestiny}
                />
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  border: "0.1px solid #00000036",
                  width: "200px",
                  position: "absolute",
                  top: "60%",
                  left: "9%",
                }}
              ></div>
              <input
                className="organization_input"
                name="managerFirstName"
                placeholder="Imię kierownika"
                value={formData.managerFirstName}
                onChange={handleNameChange}
              />
            </div>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  border: "0.1px solid #00000036",
                  width: "200px",
                  position: "absolute",
                  top: "60%",
                  left: "9%",
                }}
              ></div>
              <input
                className="organization_input"
                name="managerLastName"
                placeholder="Nazwisko kierownika"
                value={formData.managerLastName}
                onChange={handleNameChange}
              />
            </div>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  border: " 0.1px solid #00000036",
                  width: "200px",
                  position: "absolute",
                  top: "60%",
                  left: "9%",
                }}
              ></div>
              <input
                className="organization_input"
                name="managerPhone"
                placeholder="Telefon kierownika"
                value={formData.managerPhone}
                onChange={handlePhoneChange}
              />
            </div>
            <div style={{ position: "relative" }}>
              {/*<div
                style={{
                  border: " 0.1px solid #00000036",
                  width: "200px",
                  position: "absolute",
                  top: "50%",
                  left: "9%",
                }}
              ></div>*/}
              <div className="organization_input">
                <input
                  className="input_field"
                  name="newNeed"
                  placeholder="Dodaj zapotrzebowanie"
                  value={formData.newNeed}
                  onChange={onChangeHandler}
                  onKeyDown={handleKeyDown}
                />
                <button
                  className="input_button"
                  type="button"
                  onClick={addNeed}
                >
                  +
                </button>
              </div>
              <div className="organization_hashtags">
                <div className="organization_hashtags_container">
                  {formData.needs.map((need, index) => (
                    <div key={index} className="organization_hashtag">
                      <span>{`#${need}`}</span>
                      <button
                        className="input_button"
                        onClick={() => removeNeed(index)}
                      >
                        X
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="organization_input_send">
              <button className="submit_button" type="submit">
                Wyślij
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

async function getCoordinatesFromNominatim(address) {

    const url = 'https://nominatim.openstreetmap.org/search?format=json' 
    + "&q=" + encodeURIComponent(address.street + " " + address.number + " " + address.city);

    try {
        const response = await fetch(url, {
            headers: {
                'User-Agent': 'YourAppName/1.0 (your-email@example.com)' // Replace with your actual app name and contact email
            }
        });
        if (!response.ok) {
            throw new Error();
        }
        const data = await response.json();
        if (data.length === 0) {
            throw new Error('No results found');
        }
        const location = {
            lat: parseFloat(data[0].lat),
            lon: parseFloat(data[0].lon)
        };
        return location;
    } catch (error) {
        console.error(error);
    }
}