import React, { useState, useContext, useEffect } from "react";
import { MapContext } from "./MapProvider";
import { GET_PINS_BY_NEED } from "../../configuration/queries";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import pinIcon from "leaflet/dist/images/marker-icon.png";
import pinShadow from "leaflet/dist/images/marker-shadow.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@apollo/client";
import ApolloClient from "../../configuration/Apollo";
import FooterComponent from "./Footer";
import Organization from "./Organization";
import { search } from "../../images/base64/search";

export default function Map() {
  const { searchText, setSearchText } = useContext(MapContext);
  const { userLongitude, setUserLongitude } = useContext(MapContext);
  const { userLatitude, setUserLatitude } = useContext(MapContext);

  // const get_data =
  //   searchText === "" ? GET_ORGANIZATIONS : GET_ORGANIZATIONS_BY_NEED;

  const { error, data } = useQuery(GET_PINS_BY_NEED, {
    variables: { need: searchText.toLowerCase() },
    client: ApolloClient,
    fetchPolicy: "network-only",
  });
  const { showDetails, setShowDetails } = useContext(MapContext);
  const [clickedMarker, setClickedMarker] = useState(null);
  const [organizationData, setOrganizationData] = useState([]);

  useEffect(() => {
    let orgs = [];
    if (data && data.pins && data.pins.length > 0) {
      data.pins.forEach((pin) => {
        orgs.push(pin.organization);
      });
      setOrganizationData(orgs);
    }
  }, [data]);

  // useEffect(() => {
  //   if (data && data.pins && data.pins.length > 0) {
  //     const fetchOrganizations = async () => {
  //       try {
  //         const responses = await Promise.all(
  //           data.pins.map((pin) =>
  //             ApolloClient.query({
  //               query: GET_ORGANIZATION,
  //               variables: {
  //                 id: pin.organization.id,
  //                 userLongitude: 60.0,
  //                 userLatitude: 20.0,
  //               },
  //               fetchPolicy: "network-only",
  //             })
  //           )
  //         );
  //         setOrganizationData(
  //           responses.map((response) => response.data.organizations[0])
  //         );
  //       } catch (error) {
  //         console.error("Error fetching organization data", error);
  //       }
  //     };
  //     fetchOrganizations();
  //   }
  // }, [data]);

  // Such thing should be in useEeffect no?
  // if (error) return <p className="loading">Error: {error.message}</p>;

  function handleClick(markerNumber) {
    setShowDetails((current) => !current);
    setClickedMarker(markerNumber);
  }

  const customPinIcon = new L.Icon({
    iconUrl: pinIcon,
    shadowUrl: pinShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  return (
    <div>
      <MapContainer
        zoomControl={false}
        center={[userLatitude, userLongitude]}
        zoom={10}
        className="map"
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <form className="asd">
          <div className="input-group">
            <img
              style={{
                position: "absolute",
                height: "1.2vh",
                width: "auto",
                marginTop: "2vh",
                marginLeft: "2.5vh",
              }}
              src={search}
            />

            <input
              placeholder="Co chcesz oddać w Dobre Ręce?"
              className="search-box"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  e.preventDefault()
                }
              }}
              value={searchText}
              type="text"
              id="name"
            />
          </div>
        </form>

        {data &&
          data.pins.map((pin, index) => (
            <Marker
              key={index}
              position={[pin.latitude, pin.longitude]}
              icon={customPinIcon}
              eventHandlers={{
                click: () => {
                  handleClick(index);
                },
              }}
            />
          ))}
      </MapContainer>
      <FooterComponent />
      {showDetails && (
        <div className="organization">
          <nav>
            <a
              className="back"
              onClick={() => {
                handleClick(null);
              }}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="FontAwesomeIcon"
              />
              Wróć do mapy
            </a>
          </nav>
          {clickedMarker !== null && (
            <Organization
              markerNumber={clickedMarker}
              data={data.pins[clickedMarker].organization}
            />
          )}
        </div>
      )}
    </div>
  );
}
