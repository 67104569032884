import { gql } from "@apollo/client";

export const GET_PINS_BY_NEED = gql`
  query GetPinsByNeed($need: String) {
    pins(where: { organization: { needs: { some: { contains: $need } } } }) {
      id
      organization {
        id
        name
        address {
          streetName
          streetNumber
          city
        }
        krsNumber
        manager {
          name
          surname
          phoneNumber
          photo
        }
        needs
        logo
        photo
        fullDescription
      }
      latitude
      longitude
      label
    }
  }
`;

export const GET_ORGANIZATIONS_BY_NEED_AND_COORDINATES_IN_RADIUS = gql`
  query GetOrganizationsByNeedAndCoordinates(
    $need: String
    $userLongitude: Float!
    $userLatitude: Float!
    $radius: Float!
  ) {
    organizations(
      where: {
        needs: { some: { contains: $need } }
        and: { distanceFromUser: { lte: $radius } }
      }
      userLongitude: $userLongitude
      userLatitude: $userLatitude
      order: { distanceFromUser: ASC }
    ) {
      id
      name
      address {
        streetName
        streetNumber
        city
      }
      needs
      distanceFromUser
      logo
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query GetOrganization($id: UUID!) {
    organizations(where: { id: { eq: $id } }) {
      id
      name
      address {
        streetName
        streetNumber
        city
      }
      krsNumber
      manager {
        name
        surname
        phoneNumber
        photo
      }
      needs
      logo
      photo
      fullDescription
    }
  }
`;
