import { gql } from "@apollo/client";

export const ACCEPT_INVITATION = gql`
  mutation acceptInvitation(
    $email: String!
    $token: String!
    $password: String!
  ) {
    organization {
      acceptInvitation(
        input: { email: $email, token: $token, password: $password }
      )
    }
  }
`;
export const ADD_ORGANIZATION = gql`
  mutation add($addInput: AddOrganizationInput!) {
    organization {
      add(input: $addInput) {
        recordId
      }
    }
  }
`;

export const EDIT_ORGANIZATION = gql`
  mutation edit($editInput: EditOrganizationInput!) {
    organization {
      edit(input: $editInput) {
        recordId
      }
    }
  }
`;
// export const EDIT_ORGANIZATION = gql`
//   mutation edit($editInput: EditOrganizationInput!) {
//     organization {
//       edit(input: $editInput) {
//         recordId
//     }
//   }
// }
// `;
// export const WHO_AM_I = gql`
// query {
//   whoAmI {
//     organization {
//       // Define the fields you want to fetch here
//       distanceFromUser
//       email
//       fieldOfOperation
//       fullDescription
//       id
//       krsCategory
//       krsNumber
//       latitude
//       logo
//       longitude
//       name
//       needs
//       photo
//       shortDescription
//       manager {
//         id
//         name
//         phoneNumber
//         photo
//         surname
//       }
//       address {
//         city
//         streetName
//         streetNumber
//       }
//     }
//   }
// }
// `;
