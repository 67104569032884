import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import { GET_ORGANIZATIONS_BY_NEED_AND_COORDINATES_IN_RADIUS } from "../../configuration/queries";
import ApolloClient from "../../configuration/Apollo";
import Organization from "./Organization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faL } from "@fortawesome/free-solid-svg-icons";
import { MapContext } from "./MapProvider";
import { placesLocation } from "../../images/base64/placesLocation";
import { placesImage } from "../../images/base64/placesImages";

const Places = () => {
  const { searchText, setSearchText } = useContext(MapContext);
  const { userLongitude, setUserLongitude } = useContext(MapContext);
  const { userLatitude, setUserLatitude } = useContext(MapContext);

  const {
    loading: loadingData,
    error: errorData,
    data,
  } = useQuery(GET_ORGANIZATIONS_BY_NEED_AND_COORDINATES_IN_RADIUS, {
    variables: {
      need: searchText,
      userLongitude: userLongitude,
      userLatitude: userLatitude,
      radius: 15.0,
    },
    client: ApolloClient,
    fetchPolicy: "network-only",
  });

  const [organizationData, setOrganizationData] = useState([]);
  const [loadingOrganization, setLoadingOrganization] = useState(false);
  const [errorOrganization, setErrorOrganization] = useState(null);
  const { details, setDetails } = useContext(MapContext);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    if (data && data.organizations) {
      setLoadingOrganization(true);
      setOrganizationData(data.organizations);
      setLoadingOrganization(false);
    }
  }, [data]);

  // useEffect(() => {
  //   if (data && data.pins && data.pins.length > 0) {
  //     setLoadingOrganization(true);
  //     Promise.all(
  //       data.pins.map((pin) =>
  //         ApolloClient.query({
  //           query: GET_ORGANIZATION,
  //           variables: {
  //             id: pin.organization.id,
  //             userLongitude: 18.38,
  //             userLatitude: 54.21,
  //           },
  //           fetchPolicy: "network-only",
  //         })
  //       )
  //     )
  //       .then((responses) => {
  //         setOrganizationData(
  //           responses.map((response) => response.data.organizations[0])
  //         );
  //       })
  //       .catch((error) => {
  //         setErrorOrganization(error);
  //       })
  //       .finally(() => {
  //         setLoadingOrganization(false);
  //       });
  //   }
  // }, [data]);

  if (loadingData || loadingOrganization)
    return <p className="loading">Loading...</p>;
  if (errorData) return <p className="error">Error: {errorData.message}</p>;
  if (errorOrganization)
    return <p className="error">Error: {errorOrganization.message}</p>;

  const needsLists = organizationData.map((organization, index) => {
    const needsList = organization.needs.map((need, needIndex) => (
      <li key={`${index}-${needIndex}`}>
        <span>{need}</span>
      </li>
    ));

    return <ul key={index}>{needsList}</ul>;
  });

  function toggle(index) {
    setSelectedIndex(index);
    setDetails((prev) => !prev);
  }

  function handleClick() {
    setDetails((current) => !current);
  }

  return (
    <div>
      {!details && (
        <div className="places_organization">
          <p style={{ width: "100%", textAlign: "center" }}>
            Lista Organizacji - 15 km od Ciebie
          </p>
          {organizationData.map((organization, index) => (
            <div className="places-design" key={index}>
              <div className="part1_places">
                <div className="part1_text">{organization.name}</div>
                <div>
                  {organization.logo && (
                    <img
                      className="places_picture"
                      src={`data:image/png;base64,${organization.logo}`}
                    />
                  )}
                </div>
              </div>
              <div className="places_line"></div>
              <div>
                <div className="part2_text">
                  <div className="part2Text">ZAPOTRZEBOWANIE</div>
                  <div className="part2_text_list">{needsLists[index]}</div>
                </div>
              </div>
              <div className="places_line"></div>
              <div className="part3_places">
                <div className="part3_text">
                  <div>
                    <div className="Places_location">
                      <img src={placesLocation} />
                      <div>{organization.distanceFromUser.toFixed(1)} km</div>
                    </div>{" "}
                  </div>

                  <div>
                    ul. {organization.address.streetName}{" "}
                    {organization.address.streetNumber}
                  </div>
                  <div>{organization.address.city}</div>
                </div>
                <div>
                  <div>
                    <button
                      className="places_button"
                      onClick={() => toggle(index)}
                    >
                      <img className="Places_img" src={placesImage} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {details && (
        <div className="organization">
          <nav>
            <a
              className="back"
              onClick={() => {
                handleClick(null);
              }}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="FontAwesomeIcon"
              />
              Wróć
            </a>
          </nav>
          <Organization data={data.organizations[selectedIndex]} />
        </div>
      )}
    </div>
  );
};

export default Places;
